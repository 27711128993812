import React, { FC, useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import { ReactComponent as Panel } from "../../assets/icons/painel.svg";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as ProductIcon } from "../../assets/icons/product.svg";
import { ReactComponent as Exit } from "../../assets/icons/exit.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { Outlet, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { IGetUser } from "../../ts/interfaces/interface";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import {useMutation} from "react-query";
import {getUser} from "../../services/services";
import {ToastContainer} from "react-toastify";

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
    const [user, setUser] = useState<IGetUser | undefined>();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["jwt_token_gestaoconcursos"]);
    const [isCollapsed, setIsCollapsed] = useState(false);


    const mutation = useMutation<IGetUser, Error>(() => getUser(), {
        onSuccess: (data) => {
            setUser(data);
        },
        onError: (error) => {
            console.error('Erro ao buscar nome do usuário:', error);
        }
    });

    useEffect(() => {
        mutation.mutate();
    }, []);


    function logout() {
        setCookie("jwt_token_gestaoconcursos", "", {
            domain: window.location.hostname,
            path: "/",
            maxAge: -1
        });
        localStorage.removeItem('jwt_token_gestaoconcursos');
        navigate("/");
    }

    return (
        <>
            <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ''}`}>
                <div className={styles.sidebarHeader}>
                    <div className={styles.alignTitle}>
                        <p className={styles.sidebarTitle}>Gestão de <span>Concursos</span></p>
                    </div>
                    <p>Olá {user?.data.name}</p>
                    <Button
                        width="100px"
                        height="25px"
                        theme="close"
                        fontSize="12px"
                        borderRadius="6px"
                        icon={<Exit />}
                        onClick={logout}
                    >
                        Sair
                    </Button>
                    <button className={styles.menuButton} onClick={() => setIsCollapsed(!isCollapsed)}>
                        <MenuIcon/>
                    </button>
                </div>

                <ul className={styles.sidebarMenu}>
                    <div className={styles.controlPanel} onClick={() => navigate('/')}>
                        <i><Panel /></i>
                        <span>Início</span>
                    </div>
                    <Dropdown className={styles.DropdownClose} label="Concursos" icon={<ProductIcon/>}>
                        <li onClick={() => navigate('/public-tenders')}>Concursos</li>
                        <li onClick={() => navigate('/institutions')}>Instituições</li>
                        <li onClick={() => navigate('/examining-boards')}>Bancas</li>
                        <li onClick={() => navigate('/occupations')}>Cargos</li>
                        <li onClick={() => navigate('/careers')}>Carreiras</li>
                        <li onClick={() => navigate('/states')}>Estados</li>
                    </Dropdown>
                    {/*<Dropdown className={styles.DropdownClose} label="Vendas" icon={<Cart />} />*/}
                    {/*<Dropdown className={styles.DropdownClose} label="Financeiro" icon={<Dollar />} />*/}
                    {/*<Dropdown className={styles.DropdownClose} label="Minha conta" icon={<UserIcon />} />*/}
                </ul>
            </div>
            <div className={`${styles.mainContainer} ${isCollapsed ? styles.expanded : ''}`}>
                <Outlet />
            </div>
        </>
    );
};

export default Sidebar;
