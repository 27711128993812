import api from './api';
import {UserLoginForm} from "../views/Login/Login";
import {ChangePasswordForm} from "../views/Login/ChangePassword";
import {IGetUser} from "../ts/interfaces/interface";
import {IGetInstitutions, IPostInstitutions} from "../ts/interfaces/Institution/interface";
import {IGetOccupations, IPostOccupations} from "../ts/interfaces/Occupation/interface";
import {IGetState, IPostStates} from "../ts/interfaces/state/interface";
import {IGetCareers, IPostCareers} from "../ts/interfaces/Career/interface";
import {IGetExaminingBoards, IPostExaminingBoards} from "../ts/interfaces/ExaminingBoard/interface";
import { IFilterPublicTenders, IGetPublicTenders, IPostPublicTenders } from '../ts/interfaces/PublicTender/interface';
import {IGetSeo, IPostSeo} from "../ts/interfaces/Seo/interface";


// @ts-ignore
export const serialize = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

// @ts-ignore
export const serializeArray = (obj) => {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                for (let o in obj[p]) {
                    str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][o]));
                }
            } else {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }
    return str.join("&");
}

//User
export const login = (data: UserLoginForm) => Promise.resolve(api.post('/v1/auth/login', data));
export const rememberPassword = (data: {
    email: string,
    token: string | null
}) => Promise.resolve(api.post(`/v1/auth/remember-password`, data));
export const changePassword = (data: ChangePasswordForm) => Promise.resolve(api.put(`/v1/auth/user/password`, data));
export const getUser = (): Promise<IGetUser> => (api.get(`/v1/auth/user/me`));

//Institutions
export const getInstitutions = (data: IGetInstitutions) => Promise.resolve(api.get('/v1/institution/get-all', {params: data}));
export const storeInstitution = (data: IPostInstitutions) => Promise.resolve(api.post('/v1/institution/store', data));
export const getInstitution = (id: number) => Promise.resolve(api.get('/v1/institution/get/' + id));
export const deleteInstitution = (id: number) => Promise.resolve(api.post('/v1/institution/' + id + '/delete'));
export const filterInstitutions = () => Promise.resolve(api.get('/v1/institution/filter', ));

//Occupations
export const getOccupations = (data: IGetOccupations) => Promise.resolve(api.get('/v1/occupation/get-all', {params: data}));
export const storeOccupation = (data: IPostOccupations) => Promise.resolve(api.post('/v1/occupation/store', data));
export const getOccupation = (id: number) => Promise.resolve(api.get('/v1/occupation/get/' + id));
export const deleteOccupation = (id: number) => Promise.resolve(api.post('/v1/occupation/' + id + '/delete'));
export const filterOccupations = () => Promise.resolve(api.get('/v1/occupation/filter', ));


//States
export const getStates = (data: IGetState) => Promise.resolve(api.get('/v1/state/get-all', {params: data}));
export const storeState = (data: IPostStates) => Promise.resolve(api.post('/v1/state/store', data));

//Careers
export const getCareers = (data: IGetCareers) => Promise.resolve(api.get('/v1/career/get-all', {params: data}));
export const storeCareer = (data: IPostCareers) => Promise.resolve(api.post('/v1/career/store', data, {
    headers: {'Content-Type': 'multipart/form-data'}
}));
export const getCareer = (id: number) => Promise.resolve(api.get('/v1/career/get/' + id));
export const deleteCareer = (id: number) => Promise.resolve(api.post('/v1/career/' + id + '/delete'));
export const filterCareers = () => Promise.resolve(api.get('/v1/career/filter', ));


//ExaminingBoard
export const getExaminingBoard = (id: number) => Promise.resolve(api.get('/v1/examining-board/get/' + id));
export const storeExaminingBoard = (data: FormData) => Promise.resolve(api.post('/v1/examining-board/store', data,
    {headers: {'Content-Type': 'multipart/form-data'}}));
export const deleteExaminingBoard = (id: number) => Promise.resolve(api.post('/v1/examining-board/' + id + '/delete'));
export const getExaminingBoards = (data: IGetExaminingBoards) => Promise.resolve(api.get('/v1/examining-board/get-all', {params: data}));
export const filterExaminingBoards = () => Promise.resolve(api.get('/v1/examining-board/filter', ));

//PublicTender 
export const getPublicTender = (id: number) => Promise.resolve(api.get('/v1/public-tender/get/' + id));
export const storePublicTender = (data: FormData) => Promise.resolve(api.post('/v1/public-tender/store', data, 
    {headers: {'Content-Type': 'multipart/form-data'}}));
export const deletePublicTender = (id: number) => Promise.resolve(api.post('/v1/public-tender/' + id + '/delete'));
export const getPublicTenders = (data: IGetPublicTenders) => Promise.resolve(api.get('/v1/public-tender/get-all', {params: data}));

export const getSeos = (data: IGetSeo) => Promise.resolve(api.get('/v1/seo/get', {params: data}));

export const getExams = (id: number) => Promise.resolve(api.get('/v1/exam/get/' + id));
export const deleteExam = (id: number) => Promise.resolve(api.post('/v1/exam/' + id + '/delete'));