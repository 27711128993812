import React, {useState} from 'react';
import {useQuery} from 'react-query';
import styles from '../../../styles/PublicTender/styles.module.scss';
import {ReactComponent as Pencil} from '../../../assets/icons/pencil.svg';
import {ReactComponent as Download} from '../../../assets/icons/download.svg';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import Button from "../../../components/Button/Button";
import {getStates, serializeArray} from "../../../services/services";
import {BarLoader} from "react-spinners";
import {getToken} from "../../../services/auth";
import {IGetState, IStateResponse} from "../../../ts/interfaces/state/interface";
import {useNavigate} from "react-router-dom";

const State: React.FC = () => {
    const [filter, setFilter] = useState<IGetState>({
        name: '',
        acronym: ''
    });

    const navigate = useNavigate();

    const {data, isLoading} = useQuery<IStateResponse>(['states', filter], () => getStates(filter), {
        staleTime: Infinity,
    });

    const handleDownloadCSV = () => {
        let link = document.getElementById('download_csv');
        link?.click();
    };

    return (
        <div className={styles.container}>
            <h1>Estados</h1>

            <Formik initialValues={{name: '', acronym: ''}}
                    onSubmit={(values) => {
                        setFilter({...filter, name: values.name, acronym: values.acronym});
                    }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Pesquisar por nome"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="name" component="div" className={styles.error}/>
                        <Field
                            name="acronym"
                            type="text"
                            placeholder="Pesquisar por sigla"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="acronym" component="div" className={styles.error}/>
                    </div>
                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download/>}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >

                    CSV
                </Button>
            </div>
            <div className={styles.dNone}>
                <a id="download_csv" rel="noopener noreferrer"
                   href={process.env.REACT_APP_API_URL + "/v1/state/export?" + serializeArray(filter) + '&token=' + getToken()}
                   target="_blank" download></a>
            </div>


            <div className={styles.tableProducts}>
                {isLoading ?
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <>
                        {data && data.data.states.length > 0 ?
                            <table>
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Sigla</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.states.map((state) => (
                                    <tr key={state.id}>
                                        <td>{state.name}</td>
                                        <td>{state.acronym}</td>
                                        <td>
                                            <button
                                                className={styles.editProducts}
                                                onClick={() => navigate(`/state/create/${state.id}`)}
                                            >
                                                <Pencil/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <div className={styles.alignCenter}><span>Nenhum registro encontrado.</span></div>
                            )}
                    </>
                }
            </div>
        </div>
    );
};

export default State;
