import AppRouter from './routes/Routes';
import {ToastContainer} from "react-toastify";
import React from "react";

        
function App() {
  return (
      <>
          <ToastContainer/>
          <AppRouter/>
      </>
  );
}

export default App;
