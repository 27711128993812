import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    children: React.ReactNode;
    width?: string;
    height?: string;
    disabled?: boolean;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<SelectProps> = ({
    children,
    width,
    height,
    disabled,
    value,
    onChange,
    ...props
}) => {
    return (
        <div className={styles.selectWrapper}>
            <select
                className={styles.select}
                style={{ width, height }}
                onChange={onChange}
                value={value}
                disabled={disabled}
                {...props}
            >
                {children}
            </select>
            <ArrowDown className={styles.icon} />
        </div>
    );
};

export default Select;
