import React, {useEffect, useState} from 'react';
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import arrow from "../../../../assets/icons/arrow-left.svg";

import Button from "../../../../components/Button/Button";
import Seo from "../../../../components/Seo/Seo";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useMutation} from "react-query";
import {storeState} from "../../../../services/services";
import {IPostSeo} from "../../../../ts/interfaces/Seo/interface";
import {TypeSeo} from "../../../../Enums/TypeSeo";
import {IPostStates} from "../../../../ts/interfaces/state/interface";

const CreateState: React.FC = () => {
    const [seoPages, setSeoPages] = useState<IPostSeo[]>([])
    const [params, setParams] = useState<IPostStates>({
        id: '',
        seo_pages: seoPages
    })

    const queryParams = useParams();

    useEffect(() => {
        if (queryParams?.id) {
            setParams({
                ...params,
                id: queryParams?.id,
            })
        }
    }, [queryParams])

    const mutate = useMutation((data: IPostStates) => {
        return storeState(data);
    }, {
        onSettled: (response) => {
            if (response?.data?.success) {
                toast.success('Salvo com sucesso!')

                setTimeout(() => {
                    window.location.href = '/states'
                }, 800)
            } else {
                toast.error('Erro ao salvar estado')
            }
        },
        onError: (error) => {
            toast.error('Erro ao salvar estado')
        }
    })

    useEffect(() => {
        if (params.id) {
            setParams({
                ...params,
                seo_pages: seoPages
            })
        }
    }, [seoPages]);

    return (
        <>
            <Formik initialValues={{}}
                    onSubmit={() => mutate.mutate(params)}>
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a href='/states' className={styles.btnReturn}><img src={arrow} alt="Voltar"/> Voltar</a>
                            <h1>Editar Estado</h1>
                        </div>
                        <div className={styles.box}>
                            <Seo
                                entity_id={queryParams?.id ?? null}
                                type={TypeSeo.STATE}
                                seoPages={seoPages}
                                setSeoPages={setSeoPages}
                            />
                        </div>
                        <br></br>
                        <div className={styles.box}>
                            <div className={styles.alignButtons}>
                                <Button disabled={mutate.isLoading || mutate.isSuccess} width="175px"
                                        height="50px"
                                        type="submit">Salvar</Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default CreateState;
