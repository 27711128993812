import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import Button from "../../components/Button/Button";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import eyeIcon from "../../assets/icons/eye.svg";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {useMutation} from "react-query";
import {useLocation, useNavigate} from "react-router-dom";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {toast} from "react-toastify";
import {changePassword} from "../../services/services";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export interface ChangePasswordForm {
    password: string,
    password_confirmation?: string,
    social_origin?: string,
    access_token?: string,
    sub?: string,
    token?: string | null
    code?: string,
}

const validationSchema = Yup.object({
    password: Yup.string()
        .required('Campo obrigatório')
        .min(6, 'A senha deve ter no mínimo 6 caracteres'),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'As senhas devem coincidir')
        .required('Campo obrigatório')
});

const ChangePassword: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [payload, setPayload] = useState({code: "", token: ""});
    const {search} = useLocation();
    const navigate = useNavigate();
    const {executeRecaptcha} = useGoogleReCaptcha();


    useEffect(() => {
        const code = new URLSearchParams(search).get('code');
        const token = new URLSearchParams(search).get('token');
        setPayload({code: code ? code : "", token: token ? token : ""})
    }, [search]);

    const {mutate, isLoading} = useMutation(async (data: ChangePasswordForm) => {
        return await changePassword(data).catch((error) => { handleErrorMessage(error.response); return error; });
    }, {
        onSuccess: ({response, data}) => {
            if (data && data.msg) {
                if(data.success) {

                    toast.info(data.msg, {autoClose: false});

                    setPassword("");
                    setPasswordConfirmation("");
                    setTimeout(() => {
                        navigate("/");
                    }, 300)
                } else {
                    toast.error(data.msg);
                }
            } else {
                handleErrorMessage({status: response.status, data: {errors: response.data.errors}});
            }
        },
        onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
            handleErrorMessage(error.response);
        }
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        document.title = 'Alterar senha';
    }, []);

    const onSubmit = async (data: ChangePasswordForm) => {
        let token = null;
        if (executeRecaptcha) {
            token = await executeRecaptcha('changePassword');
        }
        mutate({...data, token: token, code: payload.code})
    };

    return (
        <div className={styles.container}>
            <div className={styles.alignTitle}>
                <p className={styles.loginTitle}>Gestão de <div><span>Concursos</span></div></p>
            </div>
            <div className={styles.align}>
                <Formik
                    initialValues={{
                        password: '',
                        password_confirmation: '',
                        code: payload.code,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className={styles.titleGroup}>
                                <h1>Alterar senha</h1>
                                <span>Informe uma nova senha</span>
                            </div>
                            <div className={styles.passwordContainer}>
                                <Field
                                    name="password"
                                    className={styles.inputText}
                                    placeholder="Senha"
                                    type={showPassword ? "text" : "password"}
                                />
                                <img
                                    src={eyeIcon}
                                    alt={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
                                    onClick={togglePasswordVisibility}
                                />
                                {errors.password && touched.password ? (
                                    <div className={styles.error}>{errors.password}</div>
                                ) : null}
                            </div>
                            <div className={styles.passwordContainer}>
                                <Field
                                    name="password_confirmation"
                                    className={styles.inputText}
                                    placeholder="Confirmar senha"
                                    type={showConfirmPassword ? "text" : "password"}
                                />
                                <img
                                    src={eyeIcon}
                                    alt={showConfirmPassword ? 'Ocultar senha' : 'Mostrar senha'}
                                    onClick={toggleConfirmPasswordVisibility}
                                />
                                <ErrorMessage name="password_confirmation" component="div" className={styles.error} />
                            </div>
                            <Button width="300px" height="39px" type="submit">Confirmar</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const TheChange = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
            <ChangePassword/>
        </GoogleReCaptchaProvider>
    )
}

export default TheChange
