import React from 'react';
import {Route, Routes as Router} from 'react-router-dom';

import Main from "../containers/Main";

import Dashboard from "../views/Dashboard/Dashboard";
import ExaminingBoard from "../views/PublicTender/ExaminingBoard/ExaminingBoard";
import RegisterExaminingBoard from "../views/PublicTender/ExaminingBoard/RegisterExaminingBoard/RegisterExaminingBoard";
import NotFound from "../views/NotFound/NotFound";
import Login from "../views/Login/Login";
import RequireAuth from "./RequireAuth";
import ChangePassword from "../views/Login/ChangePassword";
import RememberPassword from "../views/Login/RememberPassword";
import Institution from "../views/PublicTender/Institution/Institution";
import Career from "../views/PublicTender/Career/Career";
import CreateInstitution from "../views/PublicTender/Institution/Create/CreateInstitution";
import Occupation from "../views/PublicTender/Occupation/Occupation";
import CreateOccupation from "../views/PublicTender/Occupation/Create/CreateOccupation";
import State from "../views/PublicTender/State/State";
import CreateCareer from "../views/PublicTender/Career/Create/CreateCareer";
import PublicTender from '../views/PublicTender/PublicTender/PublicTender';
import CreatePublicTender from '../views/PublicTender/PublicTender/Create/CreatePublicTender';
import CreateState from "../views/PublicTender/State/Create/CreateState";

export default function AppRouter() {
    return (
        <Router>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/recuperar-senha'} element={<RememberPassword/>}/>
            <Route path={'/alterar-senha'} element={<ChangePassword/>}/>
            <Route
                element={(
                    <RequireAuth>
                        <Main/>
                    </RequireAuth>
                )}>
                <Route path={'/'} element={<Dashboard/>}/>

                <Route path={'/examining-boards'} element={<ExaminingBoard/>}/>
                <Route path={'/examining-board/create/:id'} element={<RegisterExaminingBoard/>}/>
                <Route path={'/examining-board/create'} element={<RegisterExaminingBoard/>}/>

                <Route path={'/institutions'} element={<Institution/>}/>
                <Route path={'/institution/create/:id'} element={<CreateInstitution/>}/>
                <Route path={'/institution/create'} element={<CreateInstitution/>}/>

                <Route path={'/occupations'} element={<Occupation/>}/>
                <Route path={'/occupation/create/:id'} element={<CreateOccupation/>}/>
                <Route path={'/occupation/create'} element={<CreateOccupation/>}/>

                <Route path={'/states'} element={<State/>}/>
                <Route path={'/state/create/:id'} element={<CreateState/>}/>

                <Route path={'/careers'} element={<Career/>}/>
                <Route path={'/career/create/:id'} element={<CreateCareer/>}/>
                <Route path={'/career/create'} element={<CreateCareer/>}/>

                <Route path={'/public-tenders'} element={<PublicTender/>}/>
                <Route path={'/public-tender/create'} element={<CreatePublicTender />}/>
                <Route path={'/public-tender/create/:id'} element={<CreatePublicTender />}/>

            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Router>
    );
};
