export enum PublicTenderStatus {
    REQUESTED = 'REQUESTED',
    EXPECTED = 'EXPECTED',
    ANNOUNCED = 'ANNOUNCED',
    AUTHORIZED = 'AUTHORIZED',
    COMMISSIONFORMED = 'COMMISSIONFORMED Formed',
    BANKDEFINED = 'BANKDEFINED',
    BANKHIRED = 'BANKHIRED',
    BASICPROJECTPUBLISHED = 'BASICPROJECTPUBLISHED',
    NOCURRENTNOTICE = 'NOCURRENTNOTICE',
}